export default {
  'device.title': 'Parameter Setup',
  'device.system': 'System Settings',
  'device.device': 'Device Settings',
  'device.modal.title': 'Prompt',
  'device.modal.ok': 'OK',
  'device.modal.edit': 'Edit',
  'device.modal.save': 'Save',
  'device.modal.del': 'Delete',
  'device.modal.cancel': 'Cancel',
  'device.modal.operate': 'Operate',
  'device.modal.setsuccess': 'Setup Successfully',
  'device.modal.seterr': 'Setup Failed',
  'device.modal.delconfirm': 'Are you sure you want to delete the selected data?',
  'device.modal.delsuccess': 'Successfully deleted.',
  'device.modal.delerr': 'Deleted Failed',
  'device.system.basic': 'Basic Parameter',
  'device.system.basic.event': 'Exit Event',
  'device.system.basic.cycle': 'Special Plan',
  'device.system.basic.cost': 'Patrol Time',
  'device.system.basic.cost.des': 'Whether to display patrol time in Report',
  'device.system.basic.stay': 'Remain Time',
  'device.system.basic.stay.des': 'Whether to display remain time in Report',
  'device.system.basic.order': 'Sequence Patrol',
  'device.system.basic.order.des':
    'Is each point displayed in the report and executed in the order set in the plan',
  'device.system.basic.date': 'Date Formate',
  'device.system.basic.enable': 'Enable',
  'device.system.basic.disenable': 'Disable',
  'device.device.btn.parameter': 'Device Settings',
  'device.device.btn.parameter.remote': 'Remote Device Param. Setup',
  'device.device.btn.delalldevicedata': 'Confirm to clear the data in device?',
  'device.device.btn.delalldeviceclock': 'Confirm to delete the alarm clock in device',
  'device.device.btn.deldevicedata': 'Clear Device Data',
  'device.device.btn.conn': 'Communication Setup',
  'device.device.btn.delfinger': 'Remove Fingerprints',
  'device.device.btn.init': 'Device Initialization',
  'device.device.btn.initconfirm':
    'Are you sure you want to restore your device to factory settings?',
  'device.device.MsgNoReader': 'Please connecting device.',
  'device.device.MsgNoFount': 'Not found device,please confirm.',
  'device.device.MsgWriteFailed': 'Write Failed',
  'device.device.MsgOpenFailed': 'Open device failed',
  'device.device.MsgReadFailed': 'Reading data failed.',
  'device.device.MsgConnFailed': 'Connect device failed.',
  'device.device.MsgTypeFailed': 'Wrong device type',
  'device.device.noparam': 'The current device does not need to set parameters',
  'device.device.init': 'Are you sure you want to restore your device to factory settings?',
  'device.system.init': 'System Initialization',

  'device.btn.download.all': 'Download all data',
  'device.btn.download.select': 'Download selected data',

  'device.guardname': 'Guard Name',
  'device.guardname.place': 'Please input name',
  'device.guardname.des': 'Display on the screen',

  'device.clock': 'Clock Setup',
  'device.clock.sn': 'NO',
  'device.clock.name': 'Clock Time',
  'device.clock.add': 'Add Clock',
  'device.clock.del': 'Delete Clock',
  'device.clock.down': 'Download',
  'device.clock.delDown': 'Clear Download',
  'device.clock.exittime': 'The clock time entered already exists, please re-enter',
  'device.clock.nulltime': 'Please select the clock time',
  'device.clock.max': 'Alarm can only download 200 groups at most, please select again',

  'device.flashlight.closemode': 'Torch Turn Off Mode',
  'device.flashlight.closemode.dafault': 'Default',
  'device.flashlight.closemode.20': 'Turn off torch automatically after 20 seconds.',
  'device.flashlight.closemode.auto': 'Turn off Automatically',
  'device.flashlight.closemode.manual': 'Turn off Manually',
  'device.flashlight.closemode.closetime': 'Close Time',

  'device.alarm.title': 'Alarm Button',
  'device.alarm.choice1': 'Press button 1 second send alarm',
  'device.alarm.choice2': 'Press button 2 seconds send alarm',
  'device.alarm.choice3': 'Press button 3 seconds send alarm',
  'device.alarm.choice4': 'Press button 4 seconds send alarm',
  'device.alarm.choice5': 'Press button 5 seconds send alarm',
  'device.alarm.choice6': 'Press button 6 seconds send alarm',
  'device.alarm.choice7': 'Press button 7 seconds send alarm',
  'device.alarm.choice8': 'Press button 8 seconds send alarm',
  'device.alarm.choice9': 'Press button 9 seconds send alarm',
  'device.alarm.choice10': 'Press button 10 seconds send alarm',
  'device.alarm.choice11': 'Press button {count} seconds send alarm',
  'device.shuttype': 'Power off Mode',
  'device.shuttype.auto': 'Turn off Automatically',
  'device.shuttype.manual': 'Turn off Manually',
  'device.shuttype.time': 'Shutdown Time',
  'device.shuttype.timeandunit': 'Shutdown Time(minutes)',
  'device.shuttype.timeandunits': 'Shutdown Time(seconds)',
  'device.shuttype.autotime': 'Close Time',
  'device.shuttype.unit': 'Unit',
  'device.shuttype.unit.minute': 'Minutes',
  'device.shuttype.unit.seconds': 'Seconds',

  'device.screen.resolution': 'Resolution',
  'device.screen.recordtime': 'Recording Time(s)',
  'device.screen.number': 'SMS Center Number',

  'device.drop.meter1': 'One meter free fall',
  'device.drop.meter2': 'Two meter free fall',
  'device.drop.meter3': 'Three meter free fall',

  'device.step.state': 'Pedometer Status',
  'device.impact.state': 'Impact Alarm Status',
  'device.impact.incline': 'Incline Alarm State',
  'device.impact.incline.param1': 'Incline Alarm Param 1',
  'device.impact.incline.param2': 'Incline Alarm Param 2',
  'device.impact.incline.param3': 'Incline Alarm Param 3',
  'device.impact.incline.angle': 'Angle',
  'device.impact.condition': 'Impact Alarm Condition',
  'device.impact.static.param1': 'Static Alarm Param 1',
  'device.impact.static.param2': 'Static Alarm Param 2',
  'device.impact.static.param3': 'Static Alarm Param 3',
  'device.impact.static.sensitivity': 'Sensitivity',
  'device.static.condition': 'Static Alarm State',

  'device.clock.func': 'Clock Func',
  'device.clock.h2.ipmode': 'Communication Mode',
  'device.clock.h2.dynamicip': 'Dynamic IP',
  'device.clock.h2.staticip': 'Static IP',
  'device.clock.h2.ipaddress': 'IP Address',
  'device.clock.h2.subnet': 'Subnet Mask',
  'device.clock.h2.gateway': 'Gateway',
  'device.clock.h2.wifiname': 'WiFi Name',
  'device.clock.h2.wifipwd': 'WiFi Password',
  'device.clock.h2.wififunc': 'WiFi Func',
  'device.clock.nullwifiname': 'Pleasete input Wifi name',
  'device.clock.nullwifipwd': 'Pleasete input Wifi password',
  'device.clock.h2.interval': 'Alarm Reminder Interval(seconds)',
  'device.clock.h2.times': 'Alarm Reminder Number',
  'device.clock.h2.intervaltimes': 'Time Interval(seconds)',

  'device.call.type': 'Calling Prompts',
  'device.call.type.mute': 'Mute',
  'device.call.type.vibration': 'Vibration',
  'device.call.type.ring': 'Ring',
  'device.call.type.both': 'Vibration and Ring',

  'device.voice.state': 'Voice Function',
  'device.voice.volume': 'Volume',
  'device.voice.volume.high': 'High',
  'device.voice.volume.middle': 'Middle',
  'device.voice.volume.low': 'Low',

  'device.finger.state': 'Fingerprint Func',
  'device.finger.verifyone': 'on-verification once',
  'device.finger.verifycard': 'on-verification when read checkpoint',
  'device.finger.stitching': 'Fingerprint stitching times',
  'device.finger.confirmdel': 'Confirm to delete the all fingerprints information?',
  'device.devicedata.deldatafirst':
    'Remove fingerprint failed, before romove the fingerprint, please delete the data in the device!',
  'device.devicedata.confirmdel': 'Confirm to clear the data in device?',
  'device.temperature.display': 'Temperature Mode',
  'device.temperature.celsius': 'Celsius',
  'device.temperature.fahrenheit': 'Fahrenheit',

  'device.gps.working': 'Woring Mode',
  'device.gps.patrol': 'Patrol Mode',
  'device.gps.coll': 'Checkpoint Collection Mode',
  'device.gps.interval': 'Time(s)',
  'device.gps.collgps': 'Time for Collect GPS Position',

  'device.conn.title': 'Communication Setup',
  'device.conn.type': 'Connection',
  'device.conn.ip': 'Connect by IP address',
  'device.conn.domain': 'Connect by IP domain',
  'device.conn.domain.name': 'Domain',
  'device.conn.port': 'Port',
  'device.conn.isp': 'ISP',
  'device.conn.mobile': 'China Mobile',
  'device.conn.unicom': 'China Unicom',
  'device.conn.otherisp': 'Other ISP',
  'device.conn.user': 'User',
  'device.conn.pwd': 'Password',
  'device.send.mode': 'Transfer Mode',
  'device.send.auto': 'Send Data Automatically',
  'device.send.manu': 'Send Data Manually',
  'device.send.func': 'Send Button',
  'device.send.func1': 'Press button 1 second send data',
  'device.send.func2': 'Press button 2 seconds send data',
  'device.send.func3': 'Press button 3 seconds send data',
  'device.send.func4': 'Press button 4 seconds send data',
  'device.send.func5': 'Press button 5 seconds send data',
  'device.send.func6': 'Press button 6 seconds send data',
  'device.send.func7': 'Press button 7 seconds send data',
  'device.send.func8': 'Press button 8 seconds send data',
  'device.send.func9': 'Press button 9 seconds send data',
  'device.send.func10': 'Press button 10 seconds send data',
  'device.send.func11': 'Press button {count} seconds send data',

  'device.read.data.loading': 'Loading...',
  'device.read.data.blank': 'No data in the device.',
  'device.read.data.success': 'Reading successfully, total records is: ',
  'device.timing.success': 'Timing Successfully, Device code: ',
  'device.timing.failed': 'Timing Failed',
  'device.read.failed': 'Read failed',
  'device.serverTime.error': 'Failed to get server time',
  'device.timing.devicetype': 'Device Type: ',

  'device.status.disconnect': 'Device not found',
  'device.status.disconnect.desc': 'Please connect your device via USB',

  'device.socket.stop': 'Comm. Driver is not running, please launch',
  'device.socket.stop.or': ' or ',
  'device.socket.download': 'download',
  'device.socket.downloading':
    'Driver is downloading by the browser now, you can install it when it is complete',

  'device.socket.exist': 'Driver is running',
  'device.socket.exist.desc':
    'Driver is already downloaded and running now, still to download again?',

  'device.socket.busy': 'Driver is busy, please try again later...',
  'device.socket.timeout': 'Driver timeout...',

  'device.register.list': 'Registered device types',
  'device.register.list.delete': 'Sure to delete this registered device type?',
  'device.unregister.title': 'Unregistered device type',
  'device.unregister.content': 'Unregistered device type {type}, register now?',

  'device.register.success': 'Device type registered successfully',

  'device.btn.add': 'Add Device',
  'device.btn.active': 'Activate Device',
  'device.btn.replace': 'Replace Device',
  'device.btn.remark': 'Edit Remark',
  'device.btn.param': 'Remote Parameter Settings',
  'device.btn.home': 'Return Home',

  'device.table.device.code': 'Device Code',
  'device.table.device.code.des': 'Please input device code',
  'device.table.device.type': 'Device Type',
  'device.table.device.time': 'Registration Time',
  'device.table.device.account': 'Account',
  'device.table.device.name': 'Name',
  'device.table.device.extra': 'Remark',
  'device.table.device.range': 'Start and end time',

  'device.table.card.purchase': 'Purchase Quantity',
  'device.table.card.Remain': 'Remain Quantity',
  'device.table.card.time': 'Use Time',
  'device.table.card.purchasetime': 'Purchase Time',
  'device.table.card.year': ' Year',
  'device.table.card.month': ' Month',
  'device.table.card.date': ' Day',

  'device.modal.remark.title': 'Remark',
  'device.modal.remark.placeholder': 'Please input remark',
  'device.modal.remark.tip':
    'If you enter the company name, the operator under this can view this device information',

  'device.notification.description.add': 'Device added successfully',
  'device.notification.description.noneed': 'The selected device does not need to be activated',
  'device.notification.description.noend':
    'The device only can be activated after one month nearly and close to validity period.',
  'device.notification.description.type.wrong':
    'The selected device is inconsistent with the device type corresponding to the current activation code, and the operation of activating the device cannot be completed',
  'device.notification.description.success': 'Device active successfully',
  'device.notification.description.failed': 'Device active failed',
  'device.notification.description.type':
    'The type of the selected device is different from that of the current device. Therefore, the device cannot be replaced. Select a device of the same type',
  'device.notification.description.code':
    'The current device to be added is the same as the selected device number. Therefore, the device cannot be replaced. You need to replace the device with a different device number',
  'device.notification.description.confirm':
    'Are you sure to replace the original device? After the replacement, the original device can only read the data before the replacement, the valid data of the new device will start after the replacement.',
  'device.notification.description.unsupport':
    'This feature is not supported by the current access device.',
  'device.notification.description.nosite':
    'The plan does not have checkpoint, please set checkpoint first.',
  'device.notification.description.noguard':
    'No guard that can be downloaded. Please set guard first.',
  'device.notification.description.noplan':
    'No plan that can be downloaded. Please set guard first.',
  'device.notification.description.clearbook':
    'No record has been selected for downloading. If no record is selected for downloading, the address book information in the device will be cleared. Do you want to continue this operation?',
  'device.notification.description.unselect': 'Please check the device to be operated first',
};
