// 权限命名规范（暂定）：
// r - Route 代表 路由权限
// b - Button 代表 操作按钮权限
import { getSoftwareVersion } from './utils';

const softwareVersion = getSoftwareVersion();

const Auths = {
  // TODO: 翻译一级路由的权限没什么意义
  rSetup: '101',

  rDept: '10104',
  bDeptAdd: '10101',
  bDeptEdit: '10102',
  bDeptDelete: '10103',

  rEvent: '10204',
  bEventAdd: '10201',
  bEventEdit: '10202',
  bEventDelete: '10203',
  bEventExport: '10205',

  rGuard: '10304',
  bGuardAdd: '10301',
  bGuardEdit: '10302',
  bGuardDelete: '10303',
  bGuardExport: '10305',

  rSite: '10404',
  bSiteAdd: '10401',
  bSiteEdit: '10402',
  bSiteDelete: '10403',
  bSiteExport: '10405',

  rPlan: '10504',
  bPlanAdd: '10501',
  bPlanEdit: '10502',
  bPlanDelete: '10503',
  bPlanExport: '10506',

  rContent: '11004',
  rBook: '10704',
  rReceipt: '10804',
  // rDownGuard: '11304',
  rDownParam: '10904',
  rTalk: '11104',
  rVoice: '11204',
  rDuty: '11304',
  rBasic: '201',
  rHistory: '20204',
  rReport: '20304',
  rChart: '20404',
  rOmit: '20504',
  rAlarm: '20604',
  rLog: '20704',
  rRealMap: '21104',
  rRaw: '21204',
  rCalendar: '21304',
  rAttendance: '21404',
  rDevice: '30204',
  rSysParam: '30304',
  rDeviceParam: softwareVersion === 'M1.0' ? '30404' : '30401',
  rRole: '30604',
  rUser: '30704',

  // bReadData: '30503',
  bReadData: '20104',
  bClearData: softwareVersion === 'M1.0' ? '20804' : '30403',
  // bTiming: '30501',
  bTiming: softwareVersion === 'M1.0' ? '20904' : '30402',
  bConn: softwareVersion === 'M1.0' ? '30504' : '30405',
  bFinger: softwareVersion === 'M1.0' ? '21004' : '30406',
  bInit: softwareVersion === 'M1.0' ? '30804' : '30407',
  // bRegisterDevice: '30502',
  bRegisterDevice: '10604',

  // BI大屏权限
  // TODO: 目前为前台自行约定的权限
  rBI: '666666',
};

export const guestAuths = [
  Auths.rDept,
  Auths.bDeptAdd,
  Auths.bDeptEdit,
  Auths.bDeptDelete,

  Auths.rEvent,
  Auths.bEventAdd,
  Auths.bEventEdit,
  Auths.bEventDelete,
  // Auths.bEventExport,

  Auths.rGuard,
  Auths.bGuardAdd,
  Auths.bGuardEdit,
  Auths.bGuardDelete,
  // Auths.bGuardExport,

  Auths.rSite,
  Auths.bSiteAdd,
  Auths.bSiteEdit,
  Auths.bSiteDelete,
  // Auths.bSiteExport,

  Auths.rPlan,
  Auths.bPlanAdd,
  Auths.bPlanEdit,
  Auths.bPlanDelete,
  // Auths.bPlanExport,

  Auths.rHistory,
  Auths.rReport,
  Auths.rOmit,
  Auths.rAlarm,
  Auths.rLog,
  // Auths.rCalendar,
  Auths.rUser,
  Auths.rRealMap,
  Auths.rRole,
  Auths.rSysParam,
  // Auths.rDeviceParam,
  Auths.rBI,
];

export default Auths;
